<template>
  <div class="enterprise">
    <div class="switch_tab">
      <div class="store_table">
        <div
          class="store_table_item"
          v-for="(item, ii) in orderType"
          :key="ii"
          @click="storeClick(item.id)"
          :class="item.id === typeIndex ? 'active' : ''"
        >
          {{ item.code }}
        </div>
      </div>
    </div>
    <div style="background: #ffffff">
      <div class="head_img">
        <div class="img_title">
          <div class="img">
            <!-- <img :src="demandinfo.projectImg" alt="" /> -->
            <img v-if="demandinfo.projectImg" :src="demandinfo.projectImg" alt="" />
            <img v-else src="../../../assets/image/Group 3163.png" alt="" />
          </div>
          <div class="title">
            <p class="merchant_name">{{ demandinfo.projectName }}</p>
            <div class="storeCenter_item_top_left_flex_tag">
              <span v-for="(o, index) in demandinfo.areasInvestigation" :key="index">
                <span v-if="index < 2" class="office">{{ o.areas[1] }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="typeIndex == 1">
        <div style="padding:0px 30px  30px 0px;" >
          <el-button style="float:right;" type="primary" @click="addstatus('0')">加入已选</el-button>
        </div>
        <el-form ref="form" :model="form" label-width="110px">
          <el-form-item label="关联度:" prop="commodityName">
            <el-radio-group v-model="queryInfo.correlation" @change="search">
              <el-radio v-for="item in radiolist" :label="item.id" :key="item.id">{{
                item.val
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" label-width="50px">
            <div class="input-with">
              <el-input
                placeholder="请输入企业名称"
                v-model="queryInfo.companyName"
                class="input-with-select"
              >
                <el-button class="seach" slot="append" @click="search()">搜索</el-button>
              </el-input>
          
              <div class="top_info" style="margin-left: 20px">
                <span style="position: relative; top: 2px">
                  <img src="../../../assets/image/Volume Up.png" alt="" />
                </span>
                <div class="top_time" style="padding-right: 30px">
                  <div>
                    <span style="color: #75757d">最新更新时间：</span>
                    <span>{{ demandinfo.updateTime }}</span>
                  </div>

                  <div>
                    <span style="color: #75757d">更新人：</span>
                    <span>{{ demandinfo.updateName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="typeIndex == 2" style="padding: 10px 0px 10px 20px;display: flex;justify-content: space-between;">
        <div>
          <el-button type="warning" @click="addstatus('1')">发布已选</el-button>
          <el-button type="primary" @click="delstatus" plain>移除已选</el-button>
        </div>
        <div class="top_info" style="margin-left: 20px">
          <span style="position: relative; top: 2px">
            <img src="../../../assets/image/Volume Up.png" alt="" />
          </span>
          <div class="top_time" style="padding-right: 30px">
            <div>
              <span style="color: #75757d">最新更新时间：</span>
              <span>{{ demandinfo.updateTime }}</span>
            </div>

            <div>
              <span style="color: #75757d">更新人：</span>
              <span>{{ demandinfo.updateName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab_txt" v-loading="loading">
      <div class="storeCenter_item" v-for="(item, index) in storeList" :key="index">
        <div class="item_checkbox">
          <el-checkbox v-model="item.checked1" label="" @change="checkselect"></el-checkbox>
        </div>
        <div class="storeCenter_item_top">
          <div class="storeCenter_item_top_left">
            <img v-if="item.companyLogo" :src="item.companyLogo" alt="" />
            <img v-else src="../../../assets/image/Group 3237.png" alt="" />
            
            <div class="storeCenter_item_top_left_flex">
              <div style="display: flex; align-items: center">
                <h5 style="display: inline-block; margin: 0; font-size: 18px">
                  {{ item.companyFullName }}
                </h5>
              </div>
              <div class="storeCenter_item_top_left_flex_tag">
                <span v-for="(o, index) in item.labelName" :key="index">
                  <span v-if="index<3&&o!=''" class="office">{{ o }}</span>
                </span>
              </div>
              <div>
                <span style="color: #9b9a9a">关联秘书长:</span>
                <span style="padding: 0px 15px">{{ item.realName }}</span>
                <span style="color: #9b9a9a">秘书长联系电话:</span>
                <span style="padding: 0px 15px">{{ item.phone }}</span>
              </div>
            </div>
          </div>
          <div class="storeCenter_item_top_right" v-if="typeIndex != 3">
            <el-button class="buttom_right" @click="relevancy(item)">设置关联度</el-button>
            <span  class="edit" @click="delstatu(item)">移除</span>
          </div>
          <div class="storeCenter_item_top_right_time" v-if="typeIndex == 3">
            <span v-if="item.releaseTime">发布时间：{{ item.releaseTime }}</span>
          </div>
          <div class="Relevancy" v-if="item.correlation">
            <span>关联度：{{ item.correlation }}%</span>
          </div>
        </div>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog title="设置关联度" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="putcorrela" label-width="80px">
        <el-form-item label="关联度" prop="">
          <el-input v-model="putcorrela.correlation" placeholder="请输入关联度" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">保存</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getdemand,
  Companylist,
  delvisiti,
  getdemandtop,
  updateSelectedStatus,
  updateSelected
} from '@/api/demand'

export default {
  name: 'ConfigureEnterprise',
  computed: {
    inspectDemandId() {
      return this.$route.query.inspectDemandId * 1
    }
  },

  data() {
    return {
      //tab切换
      typeIndex: 1,
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        companyName: null,
        inspectDemandId: this.$route.query.inspectDemandId,
        correlation: null,
        status: '2',
        type: '2'
      },
      //关联度
      form: {},
      loading:false,
      demandinfo: {},
      orderType: [
        {
          id: 1,
          code: '自动匹配'
        },
        {
          id: 2,
          code: '确认已选'
        },
        {
          id:3,
          code:'已匹配企业'
        }
      ],
      radiolist: [
        {
          id:'',
          val:'不限'
        },
        {
          id: '90',
          val: '只选90%以上'
        },
        {
          id: '80',
          val: '只选80%以上'
        },
        {
          id: '70',
          val: '只选70%以上'
        }
      ],
      // 总条数
      total: 0,
      storeList: [],
      open: false,
      //加入已选
      selected: {
        ids: [],
        selectedStatus: '',
        inspectDemandId: this.$route.query.inspectDemandId
      },
      //设置关联度
      putcorrela: {}
    }
  },
  created() {
    this.search()
    this.getdemandtop()
  },
  methods: {
    //最近更新时间
    async getdemandtop() {
      const res = await getdemandtop({ inspectDemandId: this.inspectDemandId })
      if (res.data.resultCode == 200) {
        this.demandinfo = res.data.data
        this.demandinfo.areasInvestigation = JSON.parse(this.demandinfo.areasInvestigation)
      }
    },
    async search() {
      this.loading=true
      const res = await Companylist(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.storeList = res.data.data.list
        this.total = res.data.data.total
        this.loading=false
        this.storeList.forEach((item) => {
          if(this.typeIndex==1){
            this.$set(item, 'checked1', true)
          }else if(this.typeIndex==2){
            this.$set(item, 'checked1', false)
          }
         
          if (item.labelName != null) {
            item.labelName = item.labelName.split(',')
          
            
          }
        })
        this.checkselect()
      }
    },

    //tab切换
    storeClick(id) {
      if (id == 2) {
        this.queryInfo.status = '0'
      } else if(id==1) {
        this.queryInfo.status = '2'
      }else if(id==3){
        this.queryInfo.status = '3'
        this.queryInfo.type = null
        
      }
      this.typeIndex = id
      this.search()
    },
    relevancy(item) {
      this.putcorrela = item
      this.putcorrela = JSON.parse(JSON.stringify(this.putcorrela))

      this.open = true
    },
    //设置关联度
    submitForm() {
      updateSelected(this.putcorrela).then((response) => {
        if (response.data.resultCode == 200) {
          this.$message.success('修改成功')
          this.open = false
          this.search()
        }
      })
    },
    //取消
    cancel() {
      this.open = false
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    //已选企业
    checkselect() {
      this.selected.ids = []
      this.storeList.forEach((item) => {
        if (item.checked1) {
          this.selected.ids.push(item.id)
        }
      })
    },
    //加入/发布已经选
    async addstatus(index) {
      this.selected.selectedStatus = index
      const res = await updateSelectedStatus(this.selected)
      if (res.data.resultCode == 200) {
        this.$message.success('操作成功')
        this.search()
      }else if (this.selected.ids.length==0){
        this.$message.warning('请选择企业！')
      }
    },
    delstatus() {
      if( this.selected.ids.length>0){
        const ids = this.selected.ids
      this.$alert('您是否确认删除？')
        .then(function () {
          return delvisiti(ids)
        })
        .then(() => {
         
          this.$message.success('删除成功')
          this.search()
        })
        .catch(() => {})
      }else{
        this.$message.warning('请选择企业！')
      }
    
    },
    delstatu(row) {
      const ids = [row.id]
      this.$alert('您是否确认删除？')
        .then(function () {
          return delvisiti(ids)
        })
        .then(() => {
        
          this.$message.success('删除成功')
          this.search()
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
.enterprise {
  .storeCenter_item_top_left_flex_tag {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    padding-top: 13px;

    span {
      box-sizing: border-box;
      padding: 1px 6px;
      display: block;
      font-size: 14px;
      font-weight: 400;
      border-radius: 4px;
    }

    .office {
      position: relative;
      left: -3px;
      padding: 5px;
      color: #6090ee;
      background: #e1ecff;
    }

    .support {
      border: 1px solid #fd523f;
      color: #fd523f;
      margin-left: 12px;
    }
  }
  .top_info {
    display: flex;
    align-items: center;
    img {
      width: 18px;
      height: 17px;
    }
    .top_time {
      display: flex;
      justify-content: space-around;
      font-size: 14px;
      width: 356.84px;
      height: 38px;
      background: #fafafa;
      border-radius: 8px;
      line-height: 38px;
    }
  }
  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }
  .tab_txt {
    margin-top: 20px;

    .storeCenter_item {
      width: 100%;
      overflow: hidden;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      box-sizing: border-box;
      position: relative;
      .item_checkbox {
        line-height: 36px;
        margin: 0px 10px;
      }
      .Relevancy {
        position: absolute;
        width: 93px;
        height: 34px;
        top: -0px;
        left: -0px;
        background: #fd6161;
        border-radius: 10px 15px 15px 0px;
        font-size: 13px;
        text-align: center;
        line-height: 34px;
        color: #ffffff;
      }

      .storeCenter_item_top {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        padding-bottom: 14px;
        position: relative;
        background: #ffffff;
        padding: 16px 20px 10px 10px;

        .storeCenter_item_top_left {
          display: flex;
          flex-direction: row;

          img {
            width: 98px;
            height: 98px;
            border-radius: 4px;
            margin-right: 14px;
          }

          .storeCenter_item_top_left_flex {
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h4 {
              color: #1f2d3d;
              font-size: 26px;
              font-weight: 500;
              margin: 0;
            }

            .storeCenter_item_top_left_flex_tag {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-content: center;

              span {
                box-sizing: border-box;
                padding: 1px 6px;
                display: block;
                font-size: 14px;
                font-weight: 400;
                border-radius: 4px;
              }

              .office {
                border: 1px solid #6090ee;
                color: #6090ee;
              }

              .support {
                border: 1px solid #fd523f;
                color: #fd523f;
                margin-left: 12px;
              }
            }
          }
        }
        .storeCenter_item_top_right {
          .buttom_right {
            height: 36px;
            line-height: 1px;
          }
          .edit {
            padding-left: 20px;
            color: #4e93fb;
            cursor: pointer;
          }
          .frozen {
            padding-left: 20px;
            color: #fd5469;
            cursor: pointer;
          }
        }
        .storeCenter_item_top_right_time{
          position: relative;
          top: -30px;
          font-size: 14px;
        }
        
      }
    }
  }
  .input-with {
    width: 100%;
    overflow: hidden;
    padding: 0px 0px 20px 0px;
    display: flex;
    justify-content: space-between;
    .input-with-select {
      width: 400px;
      float: left;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
  .switch_tab {
    background: #ffffff;
    margin-top: 20px;
    .store_table {
      padding: 20px 30px;
      width: 100%;
      overflow: hidden;
      height: 48px;
      line-height: 48px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #ffffff;

      .store_table_item {
        padding: 0px 20px;
        overflow: hidden;
        line-height: 48px;
        box-sizing: border-box;
        color: #1f2d3d;
        cursor: pointer;
        text-align: center;
        font-weight: 400;
        font-size: 20px;
      }

      .active {
        border-bottom: 2px solid #4e93fb;
        color: #4e93fb;
        background-color: #fff;
      }
    }
  }
  .head_img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #ffffff;
    margin-top: 20px;
    .storeCenter_item_top_center {
      width: 600px;
    }
    .img_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 10px 5px 10px;

      img {
        width: 68px;
        height: 68px;
        border-radius: 5px;
      }

      .title {
        margin: 0px 0px 0px 10px;

        .merchant_name {
          font-weight: 550;
          font-size: 18px;
          color: #1f2d3d;
          margin: 0px;
          position: relative;
          top: -5px;
        }

        .merchant_type {
          position: relative;
          top: 10px;
          padding: 4px 8px;
          color: #2e7aff;
          background: #e1ecff;
          border-radius: 4px;
          font-size: 13px;
          line-height: 28px;
          text-align: center;
        }
      }
    }
  }
}
</style>
